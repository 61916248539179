.auth {
  .bg {
    background: #eaf0f3;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  header {
    padding: 20px 0;
    margin: 10px auto;
    display: grid;
    align-content: center;
    h1 {
      text-align: center;
      font-weight: 400;
      align-items: center;
      img {
        height: 40px;
      }
    }
  }
  footer {
    padding: 20px 0;
    margin: 10px auto;
    ul {
      list-style-type: none;
      list-style: none;
      border-bottom: 1px solid #bdc4c9;
      padding: 10px;
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      li {
        margin: 0 auto;
        a {
          color: #005ff8;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
    p {
      line-height: 40px;
      font-size: 13px;
      text-align: center;
    }
  }
}
