.manuscript {
    .records {
        .record {
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            box-shadow: 0px 0px 3px rgba(107, 107, 107, 0.5);
            align-items: center;
            cursor: pointer;
            div {
                flex-grow: 1;
                padding: 10px;
                 * {
                     margin-bottom: 5px;
                 }
                 strong {
                     color: #c02;
                 }
                 h4 {
                     font-size: 20px;
                 }
            }
            img {
                height: 30px;
                margin: 0 10px;
            }
        }
    }
}
