.article-types {
    .list {
        display: grid;
        grid-template-columns: auto;

        .article-type {
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            box-shadow: 0px 0px 3px rgba(107, 107, 107, 0.5);
            align-items: center;
            div:first-child {
                flex-grow: 1;
                padding: 10px;
                * {
                    margin-bottom: 5px;
                }
                strong {
                    color: #c02;
                }
                h4 {
                    font-size: 20px;
                }
            }
            div:last-child {
                display: flex;
                flex-direction: column;
                img {
                    height: 25px;
                    margin: 10px;
                    cursor: pointer;
                }
                .loading {
                    .spinner {
                        width: 2px;
                        height: 2px;
                        border: 2px solid #f3f3f3;
                        border-top: 2px solid #2f4f83;
                    }
                }
            }
        }
    }
}
