form {
    .form-input {
        margin-bottom: 10px;

        input,
        label, select {
          display: block;
        }
        input, select, textarea {
          background: white;
          border: 1px solid #bdc4c9;
          font-size: 16px;
          padding: 7px 6px;
          width: 100%;
          border-radius: 3px;
          box-sizing: border-box;

          &.error {
            border: 1px solid #c02;
          }
        }
        input:focus,select:focus,textarea:focus {
          outline: none;
        }
        span.error {
            color: red;
            line-height: 25px;
            font-size: 15px;
          }
      }
}