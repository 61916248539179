:root {
    --bg-dark: #565cc6;
    --bg-darker: #3e3f78;
    --text-light: #fff;
    --bg-main: #d8dde3;
}
.default-template {
    background: var(--bg-main);
    height: 100%;
    overflow: auto;

    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    section {
        display: flex;
        flex-direction: row;
        height: 100%;
        .content {
            flex-grow: 1;
            padding: 15px;
        }
        .btn {
            border-radius: 2px;
            text-transform: capitalize;
            font-size: 15px;
            padding: 10px 19px;
            text-decoration: none;
            background-color: #6472da;
            border: none;
            color: var(--text-light);
            font-weight: 400;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 10px;
        }
        .card {
            border-radius: 0;
            border: none;
            background: #fff;
            padding: 20px;
            .header {
                border-bottom: 1px solid #c5c5c5;
                margin-bottom: 20px;
                h4 {
                    padding-bottom: 10px;
                    border-bottom: 1px solid var(--bg-darker);
                    display: inline-block;
                    margin-bottom: -1px;
                }
            }

            h4 {
                font-weight: 400;
            }
            form {
                .actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    button {
                        text-transform: capitalize;
                        font-size: 15px;
                        line-height: 35px;
                        border: none;
                        color: var(--text-light);
                        font-weight: 400;
                        cursor: pointer;
                        width: 100%;
                        max-width: 150px;
                    }
                    button[type="submit"] {
                        background-color: #6472da;
                    }
                    button[type="reset"] {
                        background-color: #ecf2f6;
                        color: var(--bg-dark);
                    }
                    button:first-child {
                        border-radius: 2px 0 0 2px;
                    }
                    button:last-child {
                        border-radius: 0 2px 2px 0;
                    }
                }
                .checkbox {
                    .checked {
                        background-color: var(--bg-darker);
                    }
                }
            }
            .flag {
                line-height: 30px;
                text-align: center;
                font-weight: 400;
                font-size: 18px;
                margin-bottom: 10px;
                &.error {
                    color: #721c24;
                    background-color: #f8d7da;
                    border: 1px solid #f5c6cb;
                }
                &.success {
                    color: #2d673a;
                    background-color: #d5eddb;
                    border: 1px solid #c4e6cc;
                }
            }
        }
        table {
            width: 100%;
            margin-top: 10px;
            border-collapse: collapse;
            thead {
                background: #f4f4f4;
                th {
                    padding: 15px;
                    line-height: 25px;
                    color: #403f3f;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                }
                th:last-child {
                    border: 0;
                }
            }
            tbody {
                tr {
                    cursor: pointer;
                    background-color: #fff;
                    td {
                        padding: 15px;
                        line-height: 25px;
                        color: #020e23;
                        font-family: "DM Sans Regular";
                        font-weight: 500;
                        font-size: 17px;
                        border-bottom: 0.3px solid #d9d9d9;
                    }
                    td {
                        .actions {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            max-width: 65px;
                            img {
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
