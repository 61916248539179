.user-create {
    max-width: 500px;
    p.flag {
        line-height: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 10px;
    }
    p.flag-2 {
        color: #721c24;
        background-color: #f8d7da;
        border: 1px solid #f5c6cb;
    }
    p.flag-0 {
        color: #2D673A;
        background-color: #D5EDDB;
        border: 1px solid #C4E6CC;
    }
    form {
        .row-roles {
            display: grid;
            grid-template-columns: auto auto;
            margin-bottom: 10px;
        }
    }
}
