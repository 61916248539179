.auth {
    .login {
        background: #fff;
        box-shadow: 2px 3px 10px -7px rgba(0,0,0,.5);
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        h1 {
            font-size: 16px;
            font-weight: normal;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px solid #bdc4c9;
            margin-bottom: 10px;
            span:last-child {
                b {
                    color: #005ff8;
                    font-weight: normal;
                    cursor: pointer;
                }
            }
        }
        h4 {
            color: #721c24;
            background-color: #f8d7da;
            border: 1px solid #f5c6cb;
            line-height: 30px;
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 10px;
        }
        div.actions {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            button {
                color: #fff;
                border: 0;
                transition: 0.3s;
                line-height: 30px;
                font-weight: 400;
                width: 90px;
                background: #d98d37;
                border-radius: 3px;
                font-size: 16px;
                cursor: pointer;
            }

            button:focus {
                outline: none;
            }

            label {
                font-size: 14px;
                a {
                    text-decoration: none;
                    color: #005ff8;
                }
                input {
                    margin-right: 5px;
                }
            }
        }
    }
}
