.edit-article {
    .file {
        margin: 20px 0;
        border-radius: 5px 5px 0 0;
        padding: 10px;
        box-shadow: 0px 0px 3px rgba(107, 107, 107, 0.5);
        h4 {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}
