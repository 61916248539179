.newsletter {
    display: flex;
    flex-direction: column;
    h4 {
        background-color: #4e73df;
        color: #fff;
        text-align: center;
        padding: 5px 10px;
        border-radius: 5px 5px 0 0;
    }
    p {
        padding: 0 5px;
        line-height: 30px;
    }
    button {
        background-color: #e74a3b;
        color: #fff;
        width: 100px;
        margin: 20px auto;
        border: 0;
        padding: 6px 10px;
        cursor: pointer;
    }
}