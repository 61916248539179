header {
  .profile {
    align-self: flex-end;
    background-color: #fff;
    display: none;
  
    > div {
      justify-self: end;
      padding: 15px;
      cursor: pointer;
    }
  
    .img-cont {
      height: 40px;
      width: 40px;
      padding: 2px;
      box-sizing: border-box;
      background-color: #ddd;
      border-radius: 20px;
      float: left;
  
      img {
        height: 36px;
        width: 36px;
        border-radius: 18px;
      }
    }
    strong {
      float: left;
      line-height: 40px;
      padding: 0 10px;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    img.arrow-down {
      height: 15px;
      float: left;
      margin-top: 12px;
    }
  }
  @media (min-width: 700px) {
    .profile{
      display: grid;
      max-width: 250px;
    }
  }
}