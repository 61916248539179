.dashboard {
  .cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    .card {
      background: #fff;
      padding: 0;
      color: #37474f;
      border-radius: 5px;
      transition: all 300ms ease-in-out;
      cursor: pointer;
      box-sizing: border-box;

      &.program > div:first-child {
        background-color: #4e73df;
      }
      &.applications > div:first-child {
        background-color: #1cc88a;
      }
      &.points > div:first-child {
        background-color: #d98d37;
      }
      &.payments > div:first-child {
        background-color: #e74a3b;
      }
      &.membership > div:first-child {
        background-color: #36b9cc;
      }
      &.referrals > div:first-child {
        background-color: #f6c23e;
      }
    }
  }

  @media (min-width: 1000px) {
    .cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
