.menu {
    padding-top: 30px;
    background-color: var(--bg-dark);
    overflow: hidden;
    flex-basis: 0;
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    transition: flex-basis 300ms ease-in-out;
  }
  .menu.active {
    flex-basis: 200px;
  }
  .menu h4 {
    color: var(--text-light);
    padding: 10px 20px;
    font-weight: 400;
  }
  .menu ul {
    list-style: none;
    padding: 0;
    min-width: 200px;
  }
  .menu ul li.item {
    color: var(--text-light);
    line-height: 44px;
    box-sizing: border-box;
    border-left: 3px solid transparent;
    cursor: pointer;
    transition: background-color 300ms ease-in-out 1ms;
  }
  .menu ul li.item span {
    display: grid;
    grid-template-columns: 40px auto 30px;
    padding: 3px 10px 3px 10px;
  }
  .menu ul li.item span img:first-child {
    height: 14px;
    margin: 14px auto;
    display: block;
  }
  .menu ul li.item ul {
    height: auto;
    max-height: 0;
    transition: max-height 300ms ease-in-out 1ms;
    overflow: hidden;
    background-color: var(--bg-darker);
    padding-left: 40px;
  }
  .menu ul li.item ul li a {
    display: grid;
    grid-template-columns: 20px auto;
    line-height: 30px;
    font-size: 14px;
    padding: 10px;
    color: var(--text-light);
    text-decoration: none;
  }
  .menu ul li.item ul li a img {
    margin-top: 11px;
    height: 8px;
  }
  .menu ul li.item ul li a:hover {
    color: #fcd669;
  }
  .menu ul li.item.active {
    background-color: var(--bg-darker);
    color: var(--text-light);
    border-left-color: var(--text-light);
  }
  .menu ul li.item.active ul {
    max-height: 600px;
  }
  @media (min-width: 700px) {
    .menu {
      flex-basis: 250px;
    }
    .menu.active {
      flex-basis: 250px;
    }
  }
  