.create-page {
    max-width: 900px;
    
    form {
        .editor {
            margin-bottom: 15px;
            .ck-content {
                border: 1px solid #BDC4C9;
                border-top: 0;
                min-height: 300px;
            }
            .error {
                color: red;
            }
        }
    }
}
