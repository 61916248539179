.collaborator {
    .reject {
        width: 300px;
        padding: 10px;
        form {
            textarea {
                min-height: 120px;
            }
        }
    }
}