.collaborator {
    .detail {
        .btn {
            &.a {
                background-color: #1cc88a;
            }
            &.r {
                background-color: #e74a3b;
            }
        }
    }
}